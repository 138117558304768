<template>
  <div class="bank-account-header">
    <div class="bank-account-title">
      {{ $t('components.bank_account_header.title') }}
    </div>
    <rectoplus-button
      v-if="!hasBankAccount"
      class="bank-account-button"
      :text="$t('components.bank_account_header.register_bank_account')"
      @click="openCreateBankAccountDialog()"
    />
    <bank-account-dialog v-model="dialog" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import BankAccountDialog from '@/components/dialogs/BankAccountDialog.vue';

export default {
  name: 'BankAccountHeader',
  components: {
    RectoplusButton,
    BankAccountDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters(['getBankAccount']),

    hasBankAccount() {
      return this.getBankAccount;
    },
  },

  methods: {
    openCreateBankAccountDialog() {
      this.dialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
  .bank-account-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bank-account-title {
      font-size: 1.5em;
      font-weight: bold;
    }

    .bank-account-button {
      width: 280px;
      height: 60px;
    }
  }
</style>

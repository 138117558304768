<template>
  <v-dialog
    id="bank-account-dialog"
    v-model="dialog"
    :change="toggleDialog()"
    max-width="600"
    transition="dialog-top-transition"
  >
    <div class="card-dialog">
      <v-text-field
        v-model="pix"
        v-mask="'##.###.###/####-##'"
        background-color="field_register"
        class="input-register"
        :label="$t('components.bank_account_dialog.pix_key')"
        flat
        solo
      />

      <v-text-field
        v-model="accountNumber"
        background-color="field_register"
        class="input-register"
        :label="$t('components.bank_account_dialog.account_number')"
        flat
        solo
      />

      <v-text-field
        v-model="agency"
        background-color="field_register"
        class="input-register"
        :label="$t('components.bank_account_dialog.agency')"
        flat
        solo
      />

      <v-text-field
        v-model="bankName"
        background-color="field_register"
        class="input-register"
        :label="$t('components.bank_account_dialog.bank_name')"
        flat
        solo
      />
    </div>

    <button
      class="button-dialog"
      @click="createBusinessBankAccount"
    >
      {{ $t('components.bank_account_dialog.register_account') }}
    </button>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BankAccountDialog',
  model: {
    prop: 'openDialog',
    event: 'change',
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      pix: '',
      agency: '',
      bankName: '',
      accountNumber: '',
    };
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
  },
  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },

  methods: {
    ...mapActions([
      'createBankAccount',
    ]),
    createBusinessBankAccount() {
      this.createBankAccount({
        business_id: this.getBusiness.id,
        agency: this.agency,
        account_number: this.accountNumber,
        bank_name: this.bankName,
        pix: this.pix,
      });

      this.dialog = false;
    },
    toggleDialog() {
      this.$emit('change', this.dialog);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  padding: 40px;
  border-radius: 10px;
  background: $dialog_bank_account_background;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .input-register {
    border-radius: 8px;
  }
}

.button-dialog {
  margin-top: 40px;
  border-radius: 10px;
  color: $color_light;
  font-weight: bold;
  background: $color_primary;
  height: 70px;
}
</style>

<template>
  <div class="card-bank-account">
    <div v-if="hasBankAccount">
      <div>
        <label class="bank-account-label">
          {{ $t('components.card_bank_account.account_number') }}
        </label>
        <span>{{ getBankAccount.account_number }}</span>
      </div>

      <div>
        <label class="bank-account-label">
          {{ $t('components.card_bank_account.agency') }}
        </label>
        <span>{{ getBankAccount.agency }}</span>
      </div>

      <div>
        <label class="bank-account-label">
          {{ $t('components.card_bank_account.bank_name') }}
        </label>
        <span>{{ getBankAccount.bank_name }}</span>
      </div>

      <div>
        <label class="bank-account-label">
          {{ $t('components.card_bank_account.pix_key') }}
        </label>
        <span>
          {{ getBankAccount.pix || $t('components.card_bank_account.pix_key_not_found') }}
        </span>
      </div>
    </div>

    <div v-else>
      {{ $t('components.card_bank_account.placeholder') }}
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CardBankAccount',
  computed: {
    ...mapGetters([
      'getBusiness',
      'getBankAccount',
    ]),

    hasBankAccount() {
      return this.getBankAccount;
    },
  },
  mounted() {
    this.fetchMyBankAccount({ business_id: this.getBusiness.id });
  },

  methods: {
    ...mapActions([
      'fetchMyBankAccount',
    ]),
  },
};
</script>
<style lang="scss" scoped>
  .card-bank-account {
    display: flex;
    margin-top: 32px;
    background: $card_nfe;
    border-radius: 10px;
    padding: 38px;
    text-align: justify;

    .bank-account-label {
      font-weight: bold;
    }
  }
</style>

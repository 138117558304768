<template>
  <div class="bank-account">
    <bank-account-header />
    <card-bank-account />
  </div>
</template>
<script>
import BankAccountHeader from '@/components/headers/bank_account/BankAccountHeader.vue';
import CardBankAccount from '@/components/cards/bank_account/CardBankAccount.vue';

export default {
  name: 'BankAccount',
  components: {
    BankAccountHeader,
    CardBankAccount,
  },
};
</script>
<style lang="scss">
  .bank-account {
    background: $dashboard_background;
    min-height: 100vh;
    display: flex;
    padding: 2em 2em 2em 16em;
    flex-direction: column;
  }
</style>
